
import Page from '@root/common/base/Page';
import GridSkeletonLoader from '@root/common/components/base/loader/GridSkeletonLoader.vue';

import { FetchHeadlines, QueryResult } from '@root/modules/headlines/types/headlines';
import { Section, Data as CategoriesData } from '@root/modules/category/types/categories';
import FeedParser from '@root/common/components/helpers/FeedParser.vue';
import CategoryWithHeadlinesService from '@root/modules/category/services/CategoryWithHeadlines.service';

import Pagination from '@root/common/components/base/pagination/Pagination.vue';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import convertHeadlinesToFeed from '@root/modules/category/utils/convertHeadlinesToFeed';

import config from '@root/modules/category/config/category.config';
import rootConfig from '@root/config/root.config';
import getCategoryAdsSettings from '@root/modules/category/utils/getCategoryAdsSettings';
import buildPictureUrl from '@core/utils/buildPictureUrl';

import getCategoryPath from '@root/modules/category/utils/getCategoryPath';

interface Data {
  configCategories: string[];
  feed: Section;
  pager: QueryResult['pager'];
  category: CategoriesData | null;
  categoryInfoVisible: boolean;
  endlessIterationsCount: number;
  categoryLayout: string | undefined;
}

interface Methods {
  changePage: () => void;
  setCategoryInfoVisibility: () => void;
  buildFeed: (headlines: FetchHeadlines['data']) => void;
  loadMoreHeadlines: () => void;
  handleRedirect: () => void;
}

export default Page.extend<Data, Methods, unknown, unknown>({
  components: {
    GridSkeletonLoader,
    FeedParser,
    Pagination,
  },
  data() {
    return {
      configCategories: [],
      feed: [],
      pager: {
        count: 0,
        limit: 0,
        offset: 0,
      },
      category: null,
      categoryInfoVisible: false,
      endlessIterationsCount: 1,
      categoryLayout: undefined,
    };
  },
  beforeRouteUpdate(to, from, next) {
    const reset = !this.configCategories.includes(String(to.params.id));

    // Check if next category uses current bottom navigation
    // if it does not then reset navigation
    if (reset) {
      this.resetPageHeaderBottomNavigation();
    }

    this.resetPageAdsSettings();

    next();
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();
    this.resetPageHeaderBottomNavigation();

    next();
  },
  watch: {
    category() {
      // Get category header data from configuration api
      const { component } = this.$channelConfig('page').category;
      const channelId = this.$channelConfig('settings').id;
      const newsSlug = rootConfig.main_slug;
      const parentCategory = this.category?.parentCategory || null;
      const categorySlug = (
        parentCategory && parentCategory.slug !== channelId && parentCategory.slug !== newsSlug ? parentCategory.slug : this.category?.slug
      ) as string;
      const config = component?.header?.filter((navigation) => navigation.categories.includes(categorySlug)) || [];

      this.configCategories = config[0]?.categories || [];

      this.setPageHeaderBottomNavigation(config[0]?.navigation?.bottom);
      this.setCategoryInfoVisibility();
    },
  },
  async fetch() {
    try {
      const { params, query } = this.$route;
      const { domain, lang, categoryHeadlinesLimit } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      const variables = {
        channel: params.channel,
        domain,
        parent: params.parent,
        slug: params.slug,
        language: lang.toUpperCase(),
        page: query.page as string,
        headlinesLimit: categoryHeadlinesLimit,
        getCount: true,
      };

      const categoryWithHeadlinesService = new CategoryWithHeadlinesService({ req: this.$ssrContext?.req });
      const categoryData = await categoryWithHeadlinesService.fetch(variables);

      if (!categoryData?.category) {
        categoryWithHeadlinesService.handleError({ message: 'category.error.not_found', statusCode: 404 });
        return;
      }

      const { category, headlines } = categoryData;

      this.$store.commit('category/setCategoryData', category);

      // Redirect video/laidos category to tv-shows custom page landing
      if (category!.id === 69292494) {
        const { baseUrl } = this.$channelConfig('redirects');
        const pageUrl = !fakeDomainEnabled ? `${baseUrl}/video/puslapis/laidos` : `/video/puslapis/laidos`;
        process.server ? this.$nuxt.context.redirect(302, pageUrl) : window.location.replace(pageUrl);
        return;
      }

      // Redirect video/tinklalaides category to video-podcasts custom page landing
      if (category!.id === 94378737) {
        const { baseUrl } = this.$channelConfig('redirects');
        const pageUrl = !fakeDomainEnabled ? `${baseUrl}/video/puslapis/tinklalaides` : `/video/puslapis/tinklalaides`;
        process.server ? this.$nuxt.context.redirect(302, pageUrl) : window.location.replace(pageUrl);
        return;
      }

      if (category.parentCategory) {
        // Redirect video/laidos child category to tv-shows custom page custom route
        if (category!.parentCategory?.id === 69292494) {
          const { baseUrl } = this.$channelConfig('redirects');
          const pageUrl = !fakeDomainEnabled ? `${baseUrl}/video/puslapis/laidos/${category!.slug}` : `/video/puslapis/laidos/${category!.slug}`;
          process.server ? this.$nuxt.context.redirect(302, pageUrl) : window.location.replace(pageUrl);
          return;
        }

        // Redirect video/tinklalaides child category to video-podcasts custom page custom route
        if (category!.parentCategory?.id === 94378737) {
          const { baseUrl } = this.$channelConfig('redirects');
          const pageUrl = !fakeDomainEnabled ? `${baseUrl}/video/puslapis/tinklalaides/${category!.slug}` : `/video/puslapis/tinklalaides/${category!.slug}`;
          process.server ? this.$nuxt.context.redirect(302, pageUrl) : window.location.replace(pageUrl);
          return;
        }
      }

      // redirect multimedija categories to first article
      if (category!.channel!.name === 'Multimedija') {
        const firstCategoryArticle = headlines?.items[0];
        if (firstCategoryArticle) {
          const pageUrl = '/a/' + firstCategoryArticle.id;
          process.server ? this.$nuxt.context.redirect(301, pageUrl) : window.location.replace(pageUrl);
          return;
        }
      }

      if (!category) {
        categoryWithHeadlinesService.handleError();
        return;
      }

      this.setPageAdsSettings(getCategoryAdsSettings({ $channelConfig: this.$channelConfig, route: this.$route, category }));

      this.category = category;

      this.handleRedirect();

      if (headlines?.items && headlines?.items.length > 0) {
        this.buildFeed(headlines);
      }
    } catch (e) {
      this.handlePageError(e);
    }
  },
  head() {
    const meta = this.getPageMeta();
    const cXenseMeta = [];

    if (this.category) {
      const { name, attributes } = this.category;

      meta.setTitle({ title: attributes.title || name });

      if (attributes.description) {
        meta.description = attributes.description;
        meta.ogDescription = attributes.description;
      }
      // Sometimes we are returned an array sometimes a string
      if (attributes.keywords) {
        if (Array.isArray(attributes.keywords)) {
          meta.keywords = attributes.keywords.join();
        } else {
          meta.keywords = attributes.keywords;
        }
      }

      if (attributes.image) {
        meta.imageData = { width: 1200, height: 800, src: buildPictureUrl({ id: attributes.image.id, cropperData: attributes.image.attributes.cropperData }) };
      }

      cXenseMeta.push({ hid: 'cXenseParse:eks-article-section-id', name: 'cXenseParse:eks-article-section-id', content: String(this.category.id) });
    }

    return {
      title: meta.title,
      meta: [...meta.data, ...cXenseMeta],
      link: meta.link,
    };
  },
  mounted() {
    this.restoreScroll();
  },
  methods: {
    loadMoreHeadlines() {
      this.endlessIterationsCount++;
      this.$fetch();
    },
    changePage() {
      this.$fetch();
    },
    setCategoryInfoVisibility() {
      const bottomNavigationType = this.$store.state.navigation.header.data.bottom?.settings?.type;

      this.categoryInfoVisible = !!(this.category && !config.hideCategoryInfoOnNavigationType.includes(bottomNavigationType));
    },
    buildFeed(headlines: FetchHeadlines['data']) {
      const { component } = this.$channelConfig('page').category;
      const { domain, categoryLayout } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;

      // Convert headlines to headlines with valid href
      const headlinesWithHref = headlines.items.map((headline) => getHeadlineWithHref({ headline, domain, fakeDomainEnabled }));

      const settings = component?.BlockType102 || {};
      const adSettings = component?.BlockType26 || {};
      const feed = headlinesWithHref ? convertHeadlinesToFeed(settings, adSettings, headlinesWithHref, categoryLayout, this.endlessIterationsCount) : [];

      this.feed = feed;
      this.pager = headlines.pager;
      this.categoryLayout = categoryLayout;
    },
    handleRedirect() {
      if (!this.category) {
        return;
      }

      const redirectPath = getCategoryPath(this.category, {
        domain: this.$channelConfig('settings').domain,
        fakeDomainEnabled: this.$config.FAKE_DOMAIN_ENABLED,
        query: this.$nuxt.context.query,
        route: this.$nuxt.context.route,
      });

      this.handlePageRedirect(redirectPath);
    },
  },
});
